// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成したファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

import BreakLine_icon from '../resource/maimai_img/effect/BreakLine.png';
import Circle_icon from '../resource/maimai_img/effect/Circle.png';
import EachLine_icon from '../resource/maimai_img/effect/EachLine.png';
import EachLine1_icon from '../resource/maimai_img/effect/EachLine1.png';
import EachLine2_icon from '../resource/maimai_img/effect/EachLine2.png';
import EachLine3_icon from '../resource/maimai_img/effect/EachLine3.png';
import EachLine4_icon from '../resource/maimai_img/effect/EachLine4.png';
import Firework_icon from '../resource/maimai_img/effect/Firework.png';
import FireworkCenter_icon from '../resource/maimai_img/effect/FireworkCenter.png';
import FireworkInnerCircle_icon from '../resource/maimai_img/effect/FireworkInnerCircle.png';
import Hex_icon from '../resource/maimai_img/effect/Hex.png';
import mask_icon from '../resource/maimai_img/effect/mask.png';
import NormalLine_icon from '../resource/maimai_img/effect/NormalLine.png';
import SlideLine_icon from '../resource/maimai_img/effect/SlideLine.png';
import StarWhite_icon from '../resource/maimai_img/effect/StarWhite.png';
import StarYellow_icon from '../resource/maimai_img/effect/StarYellow.png';
import TouchEff_icon from '../resource/maimai_img/effect/TouchEff.png';
import TouchEffStar1_icon from '../resource/maimai_img/effect/TouchEffStar1.png';
import TouchEffStar2_icon from '../resource/maimai_img/effect/TouchEffStar2.png';

export const EffectIcon = {
    BreakLine: new Image(),
    Circle: new Image(),
    EachLine: new Image(),
    EachLine1: new Image(),
    EachLine2: new Image(),
    EachLine3: new Image(),
    EachLine4: new Image(),
    Firework: new Image(),
    FireworkCenter: new Image(),
    FireworkInnerCircle: new Image(),
    Hex: new Image(),
    mask: new Image(),
    NormalLine: new Image(),
    SlideLine: new Image(),
    StarWhite: new Image(),
    StarYellow: new Image(),
    TouchEff: new Image(),
    TouchEffStar1: new Image(),
    TouchEffStar2: new Image(),
};

export const initeffecticons = (onProgress: (amount: number, loaded: number, name: string) => void, onload: () => void) => {
  const amount = 19;
  let loaded = 0;
  if(EffectIcon.BreakLine.src === ''){ EffectIcon.BreakLine.src = BreakLine_icon; EffectIcon.BreakLine.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakLine'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakLine'); }
  if(EffectIcon.Circle.src === ''){ EffectIcon.Circle.src = Circle_icon; EffectIcon.Circle.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Circle'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Circle'); }
  if(EffectIcon.EachLine.src === ''){ EffectIcon.EachLine.src = EachLine_icon; EffectIcon.EachLine.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine'); }
  if(EffectIcon.EachLine1.src === ''){ EffectIcon.EachLine1.src = EachLine1_icon; EffectIcon.EachLine1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine1'); }
  if(EffectIcon.EachLine2.src === ''){ EffectIcon.EachLine2.src = EachLine2_icon; EffectIcon.EachLine2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine2'); }
  if(EffectIcon.EachLine3.src === ''){ EffectIcon.EachLine3.src = EachLine3_icon; EffectIcon.EachLine3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine3'); }
  if(EffectIcon.EachLine4.src === ''){ EffectIcon.EachLine4.src = EachLine4_icon; EffectIcon.EachLine4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'EachLine4'); }
  if(EffectIcon.Firework.src === ''){ EffectIcon.Firework.src = Firework_icon; EffectIcon.Firework.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Firework'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Firework'); }
  if(EffectIcon.FireworkCenter.src === ''){ EffectIcon.FireworkCenter.src = FireworkCenter_icon; EffectIcon.FireworkCenter.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'FireworkCenter'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'FireworkCenter'); }
  if(EffectIcon.FireworkInnerCircle.src === ''){ EffectIcon.FireworkInnerCircle.src = FireworkInnerCircle_icon; EffectIcon.FireworkInnerCircle.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'FireworkInnerCircle'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'FireworkInnerCircle'); }
  if(EffectIcon.Hex.src === ''){ EffectIcon.Hex.src = Hex_icon; EffectIcon.Hex.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hex'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hex'); }
  if(EffectIcon.mask.src === ''){ EffectIcon.mask.src = mask_icon; EffectIcon.mask.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'mask'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'mask'); }
  if(EffectIcon.NormalLine.src === ''){ EffectIcon.NormalLine.src = NormalLine_icon; EffectIcon.NormalLine.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'NormalLine'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'NormalLine'); }
  if(EffectIcon.SlideLine.src === ''){ EffectIcon.SlideLine.src = SlideLine_icon; EffectIcon.SlideLine.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'SlideLine'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'SlideLine'); }
  if(EffectIcon.StarWhite.src === ''){ EffectIcon.StarWhite.src = StarWhite_icon; EffectIcon.StarWhite.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'StarWhite'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'StarWhite'); }
  if(EffectIcon.StarYellow.src === ''){ EffectIcon.StarYellow.src = StarYellow_icon; EffectIcon.StarYellow.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'StarYellow'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'StarYellow'); }
  if(EffectIcon.TouchEff.src === ''){ EffectIcon.TouchEff.src = TouchEff_icon; EffectIcon.TouchEff.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEff'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEff'); }
  if(EffectIcon.TouchEffStar1.src === ''){ EffectIcon.TouchEffStar1.src = TouchEffStar1_icon; EffectIcon.TouchEffStar1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEffStar1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEffStar1'); }
  if(EffectIcon.TouchEffStar2.src === ''){ EffectIcon.TouchEffStar2.src = TouchEffStar2_icon; EffectIcon.TouchEffStar2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEffStar2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'TouchEffStar2'); }
};
