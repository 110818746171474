// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成したファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

import BreakStar_00_icon from '../resource/maimai_img/notes/BreakStar_00.png';
import BreakStar_01_icon from '../resource/maimai_img/notes/BreakStar_01.png';
import BreakStar_Double_00_icon from '../resource/maimai_img/notes/BreakStar_Double_00.png';
import BreakStar_Double_01_icon from '../resource/maimai_img/notes/BreakStar_Double_01.png';
import Break_00_icon from '../resource/maimai_img/notes/Break_00.png';
import Break_01_icon from '../resource/maimai_img/notes/Break_01.png';
import Break_02_icon from '../resource/maimai_img/notes/Break_02.png';
import Break_03_icon from '../resource/maimai_img/notes/Break_03.png';
import Break_04_icon from '../resource/maimai_img/notes/Break_04.png';
import Hold_00_icon from '../resource/maimai_img/notes/Hold_00.png';
import Hold_01_icon from '../resource/maimai_img/notes/Hold_01.png';
import Hold_Break_00_icon from '../resource/maimai_img/notes/Hold_Break_00.png';
import Hold_Break_01_icon from '../resource/maimai_img/notes/Hold_Break_01.png';
import Hold_Each_00_icon from '../resource/maimai_img/notes/Hold_Each_00.png';
import Hold_Each_01_icon from '../resource/maimai_img/notes/Hold_Each_01.png';
import Hold_Ex_00_icon from '../resource/maimai_img/notes/Hold_Ex_00.png';
import Hold_Ex_00_break_icon from '../resource/maimai_img/notes/Hold_Ex_00_break.png';
import Hold_Ex_00_each_icon from '../resource/maimai_img/notes/Hold_Ex_00_each.png';
import Hold_Ex_00_pink_icon from '../resource/maimai_img/notes/Hold_Ex_00_pink.png';
import Hold_Ex_01_icon from '../resource/maimai_img/notes/Hold_Ex_01.png';
import Hold_Ex_01_break_icon from '../resource/maimai_img/notes/Hold_Ex_01_break.png';
import Hold_Ex_01_each_icon from '../resource/maimai_img/notes/Hold_Ex_01_each.png';
import Hold_Ex_01_pink_icon from '../resource/maimai_img/notes/Hold_Ex_01_pink.png';
import Miss_00_icon from '../resource/maimai_img/notes/Miss_00.png';
import Miss_01_icon from '../resource/maimai_img/notes/Miss_01.png';
import Slide_00_icon from '../resource/maimai_img/notes/Slide_00.png';
import Slide_01_icon from '../resource/maimai_img/notes/Slide_01.png';
import Slide_Break_00_icon from '../resource/maimai_img/notes/Slide_Break_00.png';
import Slide_Break_01_icon from '../resource/maimai_img/notes/Slide_Break_01.png';
import Slide_Each_00_icon from '../resource/maimai_img/notes/Slide_Each_00.png';
import Slide_Each_01_icon from '../resource/maimai_img/notes/Slide_Each_01.png';
import Slide_Ex_00_icon from '../resource/maimai_img/notes/Slide_Ex_00.png';
import Slide_Ex_01_icon from '../resource/maimai_img/notes/Slide_Ex_01.png';
import Star_00_icon from '../resource/maimai_img/notes/Star_00.png';
import Star_01_icon from '../resource/maimai_img/notes/Star_01.png';
import Star_Double_00_icon from '../resource/maimai_img/notes/Star_Double_00.png';
import Star_Double_01_icon from '../resource/maimai_img/notes/Star_Double_01.png';
import Star_Each_00_icon from '../resource/maimai_img/notes/Star_Each_00.png';
import Star_Each_01_icon from '../resource/maimai_img/notes/Star_Each_01.png';
import Star_Each_Double_00_icon from '../resource/maimai_img/notes/Star_Each_Double_00.png';
import Star_Each_Double_01_icon from '../resource/maimai_img/notes/Star_Each_Double_01.png';
import Star_Ex_00_blue_icon from '../resource/maimai_img/notes/Star_Ex_00_blue.png';
import Star_Ex_00_break_icon from '../resource/maimai_img/notes/Star_Ex_00_break.png';
import Star_Ex_00_each_icon from '../resource/maimai_img/notes/Star_Ex_00_each.png';
import Star_Ex_00_pink_icon from '../resource/maimai_img/notes/Star_Ex_00_pink.png';
import Star_Ex_01_blue_icon from '../resource/maimai_img/notes/Star_Ex_01_blue.png';
import Star_Ex_01_break_icon from '../resource/maimai_img/notes/Star_Ex_01_break.png';
import Star_Ex_01_each_icon from '../resource/maimai_img/notes/Star_Ex_01_each.png';
import Star_Ex_01_pink_icon from '../resource/maimai_img/notes/Star_Ex_01_pink.png';
import Star_Ex_Double_00_blue_icon from '../resource/maimai_img/notes/Star_Ex_Double_00_blue.png';
import Star_Ex_Double_00_break_icon from '../resource/maimai_img/notes/Star_Ex_Double_00_break.png';
import Star_Ex_Double_00_each_icon from '../resource/maimai_img/notes/Star_Ex_Double_00_each.png';
import Star_Ex_Double_00_pink_icon from '../resource/maimai_img/notes/Star_Ex_Double_00_pink.png';
import Star_Ex_Double_01_blue_icon from '../resource/maimai_img/notes/Star_Ex_Double_01_blue.png';
import Star_Ex_Double_01_break_icon from '../resource/maimai_img/notes/Star_Ex_Double_01_break.png';
import Star_Ex_Double_01_each_icon from '../resource/maimai_img/notes/Star_Ex_Double_01_each.png';
import Star_Ex_Double_01_pink_icon from '../resource/maimai_img/notes/Star_Ex_Double_01_pink.png';
import Star_Pink_00_icon from '../resource/maimai_img/notes/Star_Pink_00.png';
import Star_Pink_01_icon from '../resource/maimai_img/notes/Star_Pink_01.png';
import Star_Pink_Double_00_icon from '../resource/maimai_img/notes/Star_Pink_Double_00.png';
import Star_Pink_Double_01_icon from '../resource/maimai_img/notes/Star_Pink_Double_01.png';
import Tap_00_icon from '../resource/maimai_img/notes/Tap_00.png';
import Tap_01_icon from '../resource/maimai_img/notes/Tap_01.png';
import Tap_02_icon from '../resource/maimai_img/notes/Tap_02.png';
import Tap_03_icon from '../resource/maimai_img/notes/Tap_03.png';
import Tap_04_icon from '../resource/maimai_img/notes/Tap_04.png';
import Tap_Each_00_icon from '../resource/maimai_img/notes/Tap_Each_00.png';
import Tap_Each_01_icon from '../resource/maimai_img/notes/Tap_Each_01.png';
import Tap_Each_02_icon from '../resource/maimai_img/notes/Tap_Each_02.png';
import Tap_Each_03_icon from '../resource/maimai_img/notes/Tap_Each_03.png';
import Tap_Each_04_icon from '../resource/maimai_img/notes/Tap_Each_04.png';
import Tap_Ex_00_break_icon from '../resource/maimai_img/notes/Tap_Ex_00_break.png';
import Tap_Ex_00_each_icon from '../resource/maimai_img/notes/Tap_Ex_00_each.png';
import Tap_Ex_00_pink_icon from '../resource/maimai_img/notes/Tap_Ex_00_pink.png';
import Tap_Ex_01_break_icon from '../resource/maimai_img/notes/Tap_Ex_01_break.png';
import Tap_Ex_01_each_icon from '../resource/maimai_img/notes/Tap_Ex_01_each.png';
import Tap_Ex_01_pink_icon from '../resource/maimai_img/notes/Tap_Ex_01_pink.png';
import Tap_Ex_02_break_icon from '../resource/maimai_img/notes/Tap_Ex_02_break.png';
import Tap_Ex_02_each_icon from '../resource/maimai_img/notes/Tap_Ex_02_each.png';
import Tap_Ex_02_pink_icon from '../resource/maimai_img/notes/Tap_Ex_02_pink.png';
import Tap_Ex_03_break_icon from '../resource/maimai_img/notes/Tap_Ex_03_break.png';
import Tap_Ex_03_each_icon from '../resource/maimai_img/notes/Tap_Ex_03_each.png';
import Tap_Ex_03_pink_icon from '../resource/maimai_img/notes/Tap_Ex_03_pink.png';
import Tap_Ex_04_break_icon from '../resource/maimai_img/notes/Tap_Ex_04_break.png';
import Tap_Ex_04_each_icon from '../resource/maimai_img/notes/Tap_Ex_04_each.png';
import Tap_Ex_04_pink_icon from '../resource/maimai_img/notes/Tap_Ex_04_pink.png';
import Tap_Trap_00_icon from '../resource/maimai_img/notes/Tap_Trap_00.png';
import Tap_Trap_01_icon from '../resource/maimai_img/notes/Tap_Trap_01.png';
import Tap_Trap_02_icon from '../resource/maimai_img/notes/Tap_Trap_02.png';
import Tap_Trap_03_icon from '../resource/maimai_img/notes/Tap_Trap_03.png';
import Tap_Trap_04_icon from '../resource/maimai_img/notes/Tap_Trap_04.png';
import touch_icon from '../resource/maimai_img/notes/touch.png';
import touch_break_icon from '../resource/maimai_img/notes/touch_break.png';
import touch_break_center_icon from '../resource/maimai_img/notes/touch_break_center.png';
import touch_center_icon from '../resource/maimai_img/notes/touch_center.png';
import touch_each_icon from '../resource/maimai_img/notes/touch_each.png';
import touch_each_center_icon from '../resource/maimai_img/notes/touch_each_center.png';
import touch_each_three_icon from '../resource/maimai_img/notes/touch_each_three.png';
import touch_each_two_icon from '../resource/maimai_img/notes/touch_each_two.png';
import touch_hold_1_icon from '../resource/maimai_img/notes/touch_hold_1.png';
import touch_hold_2_icon from '../resource/maimai_img/notes/touch_hold_2.png';
import touch_hold_3_icon from '../resource/maimai_img/notes/touch_hold_3.png';
import touch_hold_4_icon from '../resource/maimai_img/notes/touch_hold_4.png';
import touch_hold_center_miss_icon from '../resource/maimai_img/notes/touch_hold_center_miss.png';
import touch_hold_gage_icon from '../resource/maimai_img/notes/touch_hold_gage.png';
import touch_hold_gage_break_icon from '../resource/maimai_img/notes/touch_hold_gage_break.png';
import touch_hold_gage_miss_icon from '../resource/maimai_img/notes/touch_hold_gage_miss.png';
import touch_hold_gage_miss_break_icon from '../resource/maimai_img/notes/touch_hold_gage_miss_break.png';
import touch_hold_miss_icon from '../resource/maimai_img/notes/touch_hold_miss.png';
import touch_hold_three_icon from '../resource/maimai_img/notes/touch_hold_three.png';
import touch_hold_two_icon from '../resource/maimai_img/notes/touch_hold_two.png';
import touch_just_icon from '../resource/maimai_img/notes/touch_just.png';
import touch_slide_icon from '../resource/maimai_img/notes/touch_slide.png';
import touch_three_icon from '../resource/maimai_img/notes/touch_three.png';
import touch_trap_icon from '../resource/maimai_img/notes/touch_trap.png';
import touch_trap_center_icon from '../resource/maimai_img/notes/touch_trap_center.png';
import touch_two_icon from '../resource/maimai_img/notes/touch_two.png';
import wifi_0_icon from '../resource/maimai_img/notes/wifi_0.png';
import wifi_1_icon from '../resource/maimai_img/notes/wifi_1.png';
import wifi_10_icon from '../resource/maimai_img/notes/wifi_10.png';
import wifi_2_icon from '../resource/maimai_img/notes/wifi_2.png';
import wifi_3_icon from '../resource/maimai_img/notes/wifi_3.png';
import wifi_4_icon from '../resource/maimai_img/notes/wifi_4.png';
import wifi_5_icon from '../resource/maimai_img/notes/wifi_5.png';
import wifi_6_icon from '../resource/maimai_img/notes/wifi_6.png';
import wifi_7_icon from '../resource/maimai_img/notes/wifi_7.png';
import wifi_8_icon from '../resource/maimai_img/notes/wifi_8.png';
import wifi_9_icon from '../resource/maimai_img/notes/wifi_9.png';
import wifi_break_0_icon from '../resource/maimai_img/notes/wifi_break_0.png';
import wifi_break_1_icon from '../resource/maimai_img/notes/wifi_break_1.png';
import wifi_break_10_icon from '../resource/maimai_img/notes/wifi_break_10.png';
import wifi_break_2_icon from '../resource/maimai_img/notes/wifi_break_2.png';
import wifi_break_3_icon from '../resource/maimai_img/notes/wifi_break_3.png';
import wifi_break_4_icon from '../resource/maimai_img/notes/wifi_break_4.png';
import wifi_break_5_icon from '../resource/maimai_img/notes/wifi_break_5.png';
import wifi_break_6_icon from '../resource/maimai_img/notes/wifi_break_6.png';
import wifi_break_7_icon from '../resource/maimai_img/notes/wifi_break_7.png';
import wifi_break_8_icon from '../resource/maimai_img/notes/wifi_break_8.png';
import wifi_break_9_icon from '../resource/maimai_img/notes/wifi_break_9.png';
import wifi_each_0_icon from '../resource/maimai_img/notes/wifi_each_0.png';
import wifi_each_1_icon from '../resource/maimai_img/notes/wifi_each_1.png';
import wifi_each_10_icon from '../resource/maimai_img/notes/wifi_each_10.png';
import wifi_each_2_icon from '../resource/maimai_img/notes/wifi_each_2.png';
import wifi_each_3_icon from '../resource/maimai_img/notes/wifi_each_3.png';
import wifi_each_4_icon from '../resource/maimai_img/notes/wifi_each_4.png';
import wifi_each_5_icon from '../resource/maimai_img/notes/wifi_each_5.png';
import wifi_each_6_icon from '../resource/maimai_img/notes/wifi_each_6.png';
import wifi_each_7_icon from '../resource/maimai_img/notes/wifi_each_7.png';
import wifi_each_8_icon from '../resource/maimai_img/notes/wifi_each_8.png';
import wifi_each_9_icon from '../resource/maimai_img/notes/wifi_each_9.png';
import wifi_ex_0_icon from '../resource/maimai_img/notes/wifi_ex_0.png';
import wifi_ex_1_icon from '../resource/maimai_img/notes/wifi_ex_1.png';
import wifi_ex_10_icon from '../resource/maimai_img/notes/wifi_ex_10.png';
import wifi_ex_2_icon from '../resource/maimai_img/notes/wifi_ex_2.png';
import wifi_ex_3_icon from '../resource/maimai_img/notes/wifi_ex_3.png';
import wifi_ex_4_icon from '../resource/maimai_img/notes/wifi_ex_4.png';
import wifi_ex_5_icon from '../resource/maimai_img/notes/wifi_ex_5.png';
import wifi_ex_6_icon from '../resource/maimai_img/notes/wifi_ex_6.png';
import wifi_ex_7_icon from '../resource/maimai_img/notes/wifi_ex_7.png';
import wifi_ex_8_icon from '../resource/maimai_img/notes/wifi_ex_8.png';
import wifi_ex_9_icon from '../resource/maimai_img/notes/wifi_ex_9.png';

export const NoteIcon = {
    BreakStar_00: new Image(),
    BreakStar_01: new Image(),
    BreakStar_Double_00: new Image(),
    BreakStar_Double_01: new Image(),
    Break_00: new Image(),
    Break_01: new Image(),
    Break_02: new Image(),
    Break_03: new Image(),
    Break_04: new Image(),
    Hold_00: new Image(),
    Hold_01: new Image(),
    Hold_Break_00: new Image(),
    Hold_Break_01: new Image(),
    Hold_Each_00: new Image(),
    Hold_Each_01: new Image(),
    Hold_Ex_00: new Image(),
    Hold_Ex_00_break: new Image(),
    Hold_Ex_00_each: new Image(),
    Hold_Ex_00_pink: new Image(),
    Hold_Ex_01: new Image(),
    Hold_Ex_01_break: new Image(),
    Hold_Ex_01_each: new Image(),
    Hold_Ex_01_pink: new Image(),
    Miss_00: new Image(),
    Miss_01: new Image(),
    Slide_00: new Image(),
    Slide_01: new Image(),
    Slide_Break_00: new Image(),
    Slide_Break_01: new Image(),
    Slide_Each_00: new Image(),
    Slide_Each_01: new Image(),
    Slide_Ex_00: new Image(),
    Slide_Ex_01: new Image(),
    Star_00: new Image(),
    Star_01: new Image(),
    Star_Double_00: new Image(),
    Star_Double_01: new Image(),
    Star_Each_00: new Image(),
    Star_Each_01: new Image(),
    Star_Each_Double_00: new Image(),
    Star_Each_Double_01: new Image(),
    Star_Ex_00_blue: new Image(),
    Star_Ex_00_break: new Image(),
    Star_Ex_00_each: new Image(),
    Star_Ex_00_pink: new Image(),
    Star_Ex_01_blue: new Image(),
    Star_Ex_01_break: new Image(),
    Star_Ex_01_each: new Image(),
    Star_Ex_01_pink: new Image(),
    Star_Ex_Double_00_blue: new Image(),
    Star_Ex_Double_00_break: new Image(),
    Star_Ex_Double_00_each: new Image(),
    Star_Ex_Double_00_pink: new Image(),
    Star_Ex_Double_01_blue: new Image(),
    Star_Ex_Double_01_break: new Image(),
    Star_Ex_Double_01_each: new Image(),
    Star_Ex_Double_01_pink: new Image(),
    Star_Pink_00: new Image(),
    Star_Pink_01: new Image(),
    Star_Pink_Double_00: new Image(),
    Star_Pink_Double_01: new Image(),
    Tap_00: new Image(),
    Tap_01: new Image(),
    Tap_02: new Image(),
    Tap_03: new Image(),
    Tap_04: new Image(),
    Tap_Each_00: new Image(),
    Tap_Each_01: new Image(),
    Tap_Each_02: new Image(),
    Tap_Each_03: new Image(),
    Tap_Each_04: new Image(),
    Tap_Ex_00_break: new Image(),
    Tap_Ex_00_each: new Image(),
    Tap_Ex_00_pink: new Image(),
    Tap_Ex_01_break: new Image(),
    Tap_Ex_01_each: new Image(),
    Tap_Ex_01_pink: new Image(),
    Tap_Ex_02_break: new Image(),
    Tap_Ex_02_each: new Image(),
    Tap_Ex_02_pink: new Image(),
    Tap_Ex_03_break: new Image(),
    Tap_Ex_03_each: new Image(),
    Tap_Ex_03_pink: new Image(),
    Tap_Ex_04_break: new Image(),
    Tap_Ex_04_each: new Image(),
    Tap_Ex_04_pink: new Image(),
    Tap_Trap_00: new Image(),
    Tap_Trap_01: new Image(),
    Tap_Trap_02: new Image(),
    Tap_Trap_03: new Image(),
    Tap_Trap_04: new Image(),
    touch: new Image(),
    touch_break: new Image(),
    touch_break_center: new Image(),
    touch_center: new Image(),
    touch_each: new Image(),
    touch_each_center: new Image(),
    touch_each_three: new Image(),
    touch_each_two: new Image(),
    touch_hold_1: new Image(),
    touch_hold_2: new Image(),
    touch_hold_3: new Image(),
    touch_hold_4: new Image(),
    touch_hold_center_miss: new Image(),
    touch_hold_gage: new Image(),
    touch_hold_gage_break: new Image(),
    touch_hold_gage_miss: new Image(),
    touch_hold_gage_miss_break: new Image(),
    touch_hold_miss: new Image(),
    touch_hold_three: new Image(),
    touch_hold_two: new Image(),
    touch_just: new Image(),
    touch_slide: new Image(),
    touch_three: new Image(),
    touch_trap: new Image(),
    touch_trap_center: new Image(),
    touch_two: new Image(),
    wifi_0: new Image(),
    wifi_1: new Image(),
    wifi_10: new Image(),
    wifi_2: new Image(),
    wifi_3: new Image(),
    wifi_4: new Image(),
    wifi_5: new Image(),
    wifi_6: new Image(),
    wifi_7: new Image(),
    wifi_8: new Image(),
    wifi_9: new Image(),
    wifi_break_0: new Image(),
    wifi_break_1: new Image(),
    wifi_break_10: new Image(),
    wifi_break_2: new Image(),
    wifi_break_3: new Image(),
    wifi_break_4: new Image(),
    wifi_break_5: new Image(),
    wifi_break_6: new Image(),
    wifi_break_7: new Image(),
    wifi_break_8: new Image(),
    wifi_break_9: new Image(),
    wifi_each_0: new Image(),
    wifi_each_1: new Image(),
    wifi_each_10: new Image(),
    wifi_each_2: new Image(),
    wifi_each_3: new Image(),
    wifi_each_4: new Image(),
    wifi_each_5: new Image(),
    wifi_each_6: new Image(),
    wifi_each_7: new Image(),
    wifi_each_8: new Image(),
    wifi_each_9: new Image(),
    wifi_ex_0: new Image(),
    wifi_ex_1: new Image(),
    wifi_ex_10: new Image(),
    wifi_ex_2: new Image(),
    wifi_ex_3: new Image(),
    wifi_ex_4: new Image(),
    wifi_ex_5: new Image(),
    wifi_ex_6: new Image(),
    wifi_ex_7: new Image(),
    wifi_ex_8: new Image(),
    wifi_ex_9: new Image(),
};

export const initnotesicons = (onProgress: (amount: number, loaded: number, name: string) => void, onload: () => void) => {
  const amount = 161;
  let loaded = 0;
  if(NoteIcon.BreakStar_00.src === ''){ NoteIcon.BreakStar_00.src = BreakStar_00_icon; NoteIcon.BreakStar_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_00'); }
  if(NoteIcon.BreakStar_01.src === ''){ NoteIcon.BreakStar_01.src = BreakStar_01_icon; NoteIcon.BreakStar_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_01'); }
  if(NoteIcon.BreakStar_Double_00.src === ''){ NoteIcon.BreakStar_Double_00.src = BreakStar_Double_00_icon; NoteIcon.BreakStar_Double_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_Double_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_Double_00'); }
  if(NoteIcon.BreakStar_Double_01.src === ''){ NoteIcon.BreakStar_Double_01.src = BreakStar_Double_01_icon; NoteIcon.BreakStar_Double_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_Double_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'BreakStar_Double_01'); }
  if(NoteIcon.Break_00.src === ''){ NoteIcon.Break_00.src = Break_00_icon; NoteIcon.Break_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_00'); }
  if(NoteIcon.Break_01.src === ''){ NoteIcon.Break_01.src = Break_01_icon; NoteIcon.Break_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_01'); }
  if(NoteIcon.Break_02.src === ''){ NoteIcon.Break_02.src = Break_02_icon; NoteIcon.Break_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_02'); }
  if(NoteIcon.Break_03.src === ''){ NoteIcon.Break_03.src = Break_03_icon; NoteIcon.Break_03.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_03'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_03'); }
  if(NoteIcon.Break_04.src === ''){ NoteIcon.Break_04.src = Break_04_icon; NoteIcon.Break_04.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_04'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Break_04'); }
  if(NoteIcon.Hold_00.src === ''){ NoteIcon.Hold_00.src = Hold_00_icon; NoteIcon.Hold_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_00'); }
  if(NoteIcon.Hold_01.src === ''){ NoteIcon.Hold_01.src = Hold_01_icon; NoteIcon.Hold_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_01'); }
  if(NoteIcon.Hold_Break_00.src === ''){ NoteIcon.Hold_Break_00.src = Hold_Break_00_icon; NoteIcon.Hold_Break_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Break_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Break_00'); }
  if(NoteIcon.Hold_Break_01.src === ''){ NoteIcon.Hold_Break_01.src = Hold_Break_01_icon; NoteIcon.Hold_Break_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Break_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Break_01'); }
  if(NoteIcon.Hold_Each_00.src === ''){ NoteIcon.Hold_Each_00.src = Hold_Each_00_icon; NoteIcon.Hold_Each_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Each_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Each_00'); }
  if(NoteIcon.Hold_Each_01.src === ''){ NoteIcon.Hold_Each_01.src = Hold_Each_01_icon; NoteIcon.Hold_Each_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Each_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Each_01'); }
  if(NoteIcon.Hold_Ex_00.src === ''){ NoteIcon.Hold_Ex_00.src = Hold_Ex_00_icon; NoteIcon.Hold_Ex_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00'); }
  if(NoteIcon.Hold_Ex_00_break.src === ''){ NoteIcon.Hold_Ex_00_break.src = Hold_Ex_00_break_icon; NoteIcon.Hold_Ex_00_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_break'); }
  if(NoteIcon.Hold_Ex_00_each.src === ''){ NoteIcon.Hold_Ex_00_each.src = Hold_Ex_00_each_icon; NoteIcon.Hold_Ex_00_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_each'); }
  if(NoteIcon.Hold_Ex_00_pink.src === ''){ NoteIcon.Hold_Ex_00_pink.src = Hold_Ex_00_pink_icon; NoteIcon.Hold_Ex_00_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_00_pink'); }
  if(NoteIcon.Hold_Ex_01.src === ''){ NoteIcon.Hold_Ex_01.src = Hold_Ex_01_icon; NoteIcon.Hold_Ex_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01'); }
  if(NoteIcon.Hold_Ex_01_break.src === ''){ NoteIcon.Hold_Ex_01_break.src = Hold_Ex_01_break_icon; NoteIcon.Hold_Ex_01_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_break'); }
  if(NoteIcon.Hold_Ex_01_each.src === ''){ NoteIcon.Hold_Ex_01_each.src = Hold_Ex_01_each_icon; NoteIcon.Hold_Ex_01_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_each'); }
  if(NoteIcon.Hold_Ex_01_pink.src === ''){ NoteIcon.Hold_Ex_01_pink.src = Hold_Ex_01_pink_icon; NoteIcon.Hold_Ex_01_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Hold_Ex_01_pink'); }
  if(NoteIcon.Miss_00.src === ''){ NoteIcon.Miss_00.src = Miss_00_icon; NoteIcon.Miss_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Miss_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Miss_00'); }
  if(NoteIcon.Miss_01.src === ''){ NoteIcon.Miss_01.src = Miss_01_icon; NoteIcon.Miss_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Miss_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Miss_01'); }
  if(NoteIcon.Slide_00.src === ''){ NoteIcon.Slide_00.src = Slide_00_icon; NoteIcon.Slide_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_00'); }
  if(NoteIcon.Slide_01.src === ''){ NoteIcon.Slide_01.src = Slide_01_icon; NoteIcon.Slide_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_01'); }
  if(NoteIcon.Slide_Break_00.src === ''){ NoteIcon.Slide_Break_00.src = Slide_Break_00_icon; NoteIcon.Slide_Break_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Break_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Break_00'); }
  if(NoteIcon.Slide_Break_01.src === ''){ NoteIcon.Slide_Break_01.src = Slide_Break_01_icon; NoteIcon.Slide_Break_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Break_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Break_01'); }
  if(NoteIcon.Slide_Each_00.src === ''){ NoteIcon.Slide_Each_00.src = Slide_Each_00_icon; NoteIcon.Slide_Each_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Each_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Each_00'); }
  if(NoteIcon.Slide_Each_01.src === ''){ NoteIcon.Slide_Each_01.src = Slide_Each_01_icon; NoteIcon.Slide_Each_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Each_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Each_01'); }
  if(NoteIcon.Slide_Ex_00.src === ''){ NoteIcon.Slide_Ex_00.src = Slide_Ex_00_icon; NoteIcon.Slide_Ex_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Ex_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Ex_00'); }
  if(NoteIcon.Slide_Ex_01.src === ''){ NoteIcon.Slide_Ex_01.src = Slide_Ex_01_icon; NoteIcon.Slide_Ex_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Ex_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Slide_Ex_01'); }
  if(NoteIcon.Star_00.src === ''){ NoteIcon.Star_00.src = Star_00_icon; NoteIcon.Star_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_00'); }
  if(NoteIcon.Star_01.src === ''){ NoteIcon.Star_01.src = Star_01_icon; NoteIcon.Star_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_01'); }
  if(NoteIcon.Star_Double_00.src === ''){ NoteIcon.Star_Double_00.src = Star_Double_00_icon; NoteIcon.Star_Double_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Double_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Double_00'); }
  if(NoteIcon.Star_Double_01.src === ''){ NoteIcon.Star_Double_01.src = Star_Double_01_icon; NoteIcon.Star_Double_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Double_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Double_01'); }
  if(NoteIcon.Star_Each_00.src === ''){ NoteIcon.Star_Each_00.src = Star_Each_00_icon; NoteIcon.Star_Each_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_00'); }
  if(NoteIcon.Star_Each_01.src === ''){ NoteIcon.Star_Each_01.src = Star_Each_01_icon; NoteIcon.Star_Each_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_01'); }
  if(NoteIcon.Star_Each_Double_00.src === ''){ NoteIcon.Star_Each_Double_00.src = Star_Each_Double_00_icon; NoteIcon.Star_Each_Double_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_Double_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_Double_00'); }
  if(NoteIcon.Star_Each_Double_01.src === ''){ NoteIcon.Star_Each_Double_01.src = Star_Each_Double_01_icon; NoteIcon.Star_Each_Double_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_Double_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Each_Double_01'); }
  if(NoteIcon.Star_Ex_00_blue.src === ''){ NoteIcon.Star_Ex_00_blue.src = Star_Ex_00_blue_icon; NoteIcon.Star_Ex_00_blue.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_blue'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_blue'); }
  if(NoteIcon.Star_Ex_00_break.src === ''){ NoteIcon.Star_Ex_00_break.src = Star_Ex_00_break_icon; NoteIcon.Star_Ex_00_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_break'); }
  if(NoteIcon.Star_Ex_00_each.src === ''){ NoteIcon.Star_Ex_00_each.src = Star_Ex_00_each_icon; NoteIcon.Star_Ex_00_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_each'); }
  if(NoteIcon.Star_Ex_00_pink.src === ''){ NoteIcon.Star_Ex_00_pink.src = Star_Ex_00_pink_icon; NoteIcon.Star_Ex_00_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_00_pink'); }
  if(NoteIcon.Star_Ex_01_blue.src === ''){ NoteIcon.Star_Ex_01_blue.src = Star_Ex_01_blue_icon; NoteIcon.Star_Ex_01_blue.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_blue'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_blue'); }
  if(NoteIcon.Star_Ex_01_break.src === ''){ NoteIcon.Star_Ex_01_break.src = Star_Ex_01_break_icon; NoteIcon.Star_Ex_01_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_break'); }
  if(NoteIcon.Star_Ex_01_each.src === ''){ NoteIcon.Star_Ex_01_each.src = Star_Ex_01_each_icon; NoteIcon.Star_Ex_01_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_each'); }
  if(NoteIcon.Star_Ex_01_pink.src === ''){ NoteIcon.Star_Ex_01_pink.src = Star_Ex_01_pink_icon; NoteIcon.Star_Ex_01_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_01_pink'); }
  if(NoteIcon.Star_Ex_Double_00_blue.src === ''){ NoteIcon.Star_Ex_Double_00_blue.src = Star_Ex_Double_00_blue_icon; NoteIcon.Star_Ex_Double_00_blue.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_blue'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_blue'); }
  if(NoteIcon.Star_Ex_Double_00_break.src === ''){ NoteIcon.Star_Ex_Double_00_break.src = Star_Ex_Double_00_break_icon; NoteIcon.Star_Ex_Double_00_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_break'); }
  if(NoteIcon.Star_Ex_Double_00_each.src === ''){ NoteIcon.Star_Ex_Double_00_each.src = Star_Ex_Double_00_each_icon; NoteIcon.Star_Ex_Double_00_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_each'); }
  if(NoteIcon.Star_Ex_Double_00_pink.src === ''){ NoteIcon.Star_Ex_Double_00_pink.src = Star_Ex_Double_00_pink_icon; NoteIcon.Star_Ex_Double_00_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_00_pink'); }
  if(NoteIcon.Star_Ex_Double_01_blue.src === ''){ NoteIcon.Star_Ex_Double_01_blue.src = Star_Ex_Double_01_blue_icon; NoteIcon.Star_Ex_Double_01_blue.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_blue'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_blue'); }
  if(NoteIcon.Star_Ex_Double_01_break.src === ''){ NoteIcon.Star_Ex_Double_01_break.src = Star_Ex_Double_01_break_icon; NoteIcon.Star_Ex_Double_01_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_break'); }
  if(NoteIcon.Star_Ex_Double_01_each.src === ''){ NoteIcon.Star_Ex_Double_01_each.src = Star_Ex_Double_01_each_icon; NoteIcon.Star_Ex_Double_01_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_each'); }
  if(NoteIcon.Star_Ex_Double_01_pink.src === ''){ NoteIcon.Star_Ex_Double_01_pink.src = Star_Ex_Double_01_pink_icon; NoteIcon.Star_Ex_Double_01_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Ex_Double_01_pink'); }
  if(NoteIcon.Star_Pink_00.src === ''){ NoteIcon.Star_Pink_00.src = Star_Pink_00_icon; NoteIcon.Star_Pink_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_00'); }
  if(NoteIcon.Star_Pink_01.src === ''){ NoteIcon.Star_Pink_01.src = Star_Pink_01_icon; NoteIcon.Star_Pink_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_01'); }
  if(NoteIcon.Star_Pink_Double_00.src === ''){ NoteIcon.Star_Pink_Double_00.src = Star_Pink_Double_00_icon; NoteIcon.Star_Pink_Double_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_Double_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_Double_00'); }
  if(NoteIcon.Star_Pink_Double_01.src === ''){ NoteIcon.Star_Pink_Double_01.src = Star_Pink_Double_01_icon; NoteIcon.Star_Pink_Double_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_Double_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Star_Pink_Double_01'); }
  if(NoteIcon.Tap_00.src === ''){ NoteIcon.Tap_00.src = Tap_00_icon; NoteIcon.Tap_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_00'); }
  if(NoteIcon.Tap_01.src === ''){ NoteIcon.Tap_01.src = Tap_01_icon; NoteIcon.Tap_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_01'); }
  if(NoteIcon.Tap_02.src === ''){ NoteIcon.Tap_02.src = Tap_02_icon; NoteIcon.Tap_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_02'); }
  if(NoteIcon.Tap_03.src === ''){ NoteIcon.Tap_03.src = Tap_03_icon; NoteIcon.Tap_03.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_03'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_03'); }
  if(NoteIcon.Tap_04.src === ''){ NoteIcon.Tap_04.src = Tap_04_icon; NoteIcon.Tap_04.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_04'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_04'); }
  if(NoteIcon.Tap_Each_00.src === ''){ NoteIcon.Tap_Each_00.src = Tap_Each_00_icon; NoteIcon.Tap_Each_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_00'); }
  if(NoteIcon.Tap_Each_01.src === ''){ NoteIcon.Tap_Each_01.src = Tap_Each_01_icon; NoteIcon.Tap_Each_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_01'); }
  if(NoteIcon.Tap_Each_02.src === ''){ NoteIcon.Tap_Each_02.src = Tap_Each_02_icon; NoteIcon.Tap_Each_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_02'); }
  if(NoteIcon.Tap_Each_03.src === ''){ NoteIcon.Tap_Each_03.src = Tap_Each_03_icon; NoteIcon.Tap_Each_03.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_03'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_03'); }
  if(NoteIcon.Tap_Each_04.src === ''){ NoteIcon.Tap_Each_04.src = Tap_Each_04_icon; NoteIcon.Tap_Each_04.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_04'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Each_04'); }
  if(NoteIcon.Tap_Ex_00_break.src === ''){ NoteIcon.Tap_Ex_00_break.src = Tap_Ex_00_break_icon; NoteIcon.Tap_Ex_00_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_break'); }
  if(NoteIcon.Tap_Ex_00_each.src === ''){ NoteIcon.Tap_Ex_00_each.src = Tap_Ex_00_each_icon; NoteIcon.Tap_Ex_00_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_each'); }
  if(NoteIcon.Tap_Ex_00_pink.src === ''){ NoteIcon.Tap_Ex_00_pink.src = Tap_Ex_00_pink_icon; NoteIcon.Tap_Ex_00_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_00_pink'); }
  if(NoteIcon.Tap_Ex_01_break.src === ''){ NoteIcon.Tap_Ex_01_break.src = Tap_Ex_01_break_icon; NoteIcon.Tap_Ex_01_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_break'); }
  if(NoteIcon.Tap_Ex_01_each.src === ''){ NoteIcon.Tap_Ex_01_each.src = Tap_Ex_01_each_icon; NoteIcon.Tap_Ex_01_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_each'); }
  if(NoteIcon.Tap_Ex_01_pink.src === ''){ NoteIcon.Tap_Ex_01_pink.src = Tap_Ex_01_pink_icon; NoteIcon.Tap_Ex_01_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_01_pink'); }
  if(NoteIcon.Tap_Ex_02_break.src === ''){ NoteIcon.Tap_Ex_02_break.src = Tap_Ex_02_break_icon; NoteIcon.Tap_Ex_02_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_break'); }
  if(NoteIcon.Tap_Ex_02_each.src === ''){ NoteIcon.Tap_Ex_02_each.src = Tap_Ex_02_each_icon; NoteIcon.Tap_Ex_02_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_each'); }
  if(NoteIcon.Tap_Ex_02_pink.src === ''){ NoteIcon.Tap_Ex_02_pink.src = Tap_Ex_02_pink_icon; NoteIcon.Tap_Ex_02_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_02_pink'); }
  if(NoteIcon.Tap_Ex_03_break.src === ''){ NoteIcon.Tap_Ex_03_break.src = Tap_Ex_03_break_icon; NoteIcon.Tap_Ex_03_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_break'); }
  if(NoteIcon.Tap_Ex_03_each.src === ''){ NoteIcon.Tap_Ex_03_each.src = Tap_Ex_03_each_icon; NoteIcon.Tap_Ex_03_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_each'); }
  if(NoteIcon.Tap_Ex_03_pink.src === ''){ NoteIcon.Tap_Ex_03_pink.src = Tap_Ex_03_pink_icon; NoteIcon.Tap_Ex_03_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_03_pink'); }
  if(NoteIcon.Tap_Ex_04_break.src === ''){ NoteIcon.Tap_Ex_04_break.src = Tap_Ex_04_break_icon; NoteIcon.Tap_Ex_04_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_break'); }
  if(NoteIcon.Tap_Ex_04_each.src === ''){ NoteIcon.Tap_Ex_04_each.src = Tap_Ex_04_each_icon; NoteIcon.Tap_Ex_04_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_each'); }
  if(NoteIcon.Tap_Ex_04_pink.src === ''){ NoteIcon.Tap_Ex_04_pink.src = Tap_Ex_04_pink_icon; NoteIcon.Tap_Ex_04_pink.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_pink'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Ex_04_pink'); }
  if(NoteIcon.Tap_Trap_00.src === ''){ NoteIcon.Tap_Trap_00.src = Tap_Trap_00_icon; NoteIcon.Tap_Trap_00.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_00'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_00'); }
  if(NoteIcon.Tap_Trap_01.src === ''){ NoteIcon.Tap_Trap_01.src = Tap_Trap_01_icon; NoteIcon.Tap_Trap_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_01'); }
  if(NoteIcon.Tap_Trap_02.src === ''){ NoteIcon.Tap_Trap_02.src = Tap_Trap_02_icon; NoteIcon.Tap_Trap_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_02'); }
  if(NoteIcon.Tap_Trap_03.src === ''){ NoteIcon.Tap_Trap_03.src = Tap_Trap_03_icon; NoteIcon.Tap_Trap_03.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_03'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_03'); }
  if(NoteIcon.Tap_Trap_04.src === ''){ NoteIcon.Tap_Trap_04.src = Tap_Trap_04_icon; NoteIcon.Tap_Trap_04.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_04'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'Tap_Trap_04'); }
  if(NoteIcon.touch.src === ''){ NoteIcon.touch.src = touch_icon; NoteIcon.touch.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch'); }
  if(NoteIcon.touch_break.src === ''){ NoteIcon.touch_break.src = touch_break_icon; NoteIcon.touch_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_break'); }
  if(NoteIcon.touch_break_center.src === ''){ NoteIcon.touch_break_center.src = touch_break_center_icon; NoteIcon.touch_break_center.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_break_center'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_break_center'); }
  if(NoteIcon.touch_center.src === ''){ NoteIcon.touch_center.src = touch_center_icon; NoteIcon.touch_center.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_center'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_center'); }
  if(NoteIcon.touch_each.src === ''){ NoteIcon.touch_each.src = touch_each_icon; NoteIcon.touch_each.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each'); }
  if(NoteIcon.touch_each_center.src === ''){ NoteIcon.touch_each_center.src = touch_each_center_icon; NoteIcon.touch_each_center.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_center'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_center'); }
  if(NoteIcon.touch_each_three.src === ''){ NoteIcon.touch_each_three.src = touch_each_three_icon; NoteIcon.touch_each_three.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_three'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_three'); }
  if(NoteIcon.touch_each_two.src === ''){ NoteIcon.touch_each_two.src = touch_each_two_icon; NoteIcon.touch_each_two.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_two'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_each_two'); }
  if(NoteIcon.touch_hold_1.src === ''){ NoteIcon.touch_hold_1.src = touch_hold_1_icon; NoteIcon.touch_hold_1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_1'); }
  if(NoteIcon.touch_hold_2.src === ''){ NoteIcon.touch_hold_2.src = touch_hold_2_icon; NoteIcon.touch_hold_2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_2'); }
  if(NoteIcon.touch_hold_3.src === ''){ NoteIcon.touch_hold_3.src = touch_hold_3_icon; NoteIcon.touch_hold_3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_3'); }
  if(NoteIcon.touch_hold_4.src === ''){ NoteIcon.touch_hold_4.src = touch_hold_4_icon; NoteIcon.touch_hold_4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_4'); }
  if(NoteIcon.touch_hold_center_miss.src === ''){ NoteIcon.touch_hold_center_miss.src = touch_hold_center_miss_icon; NoteIcon.touch_hold_center_miss.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_center_miss'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_center_miss'); }
  if(NoteIcon.touch_hold_gage.src === ''){ NoteIcon.touch_hold_gage.src = touch_hold_gage_icon; NoteIcon.touch_hold_gage.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage'); }
  if(NoteIcon.touch_hold_gage_break.src === ''){ NoteIcon.touch_hold_gage_break.src = touch_hold_gage_break_icon; NoteIcon.touch_hold_gage_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_break'); }
  if(NoteIcon.touch_hold_gage_miss.src === ''){ NoteIcon.touch_hold_gage_miss.src = touch_hold_gage_miss_icon; NoteIcon.touch_hold_gage_miss.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_miss'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_miss'); }
  if(NoteIcon.touch_hold_gage_miss_break.src === ''){ NoteIcon.touch_hold_gage_miss_break.src = touch_hold_gage_miss_break_icon; NoteIcon.touch_hold_gage_miss_break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_miss_break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_gage_miss_break'); }
  if(NoteIcon.touch_hold_miss.src === ''){ NoteIcon.touch_hold_miss.src = touch_hold_miss_icon; NoteIcon.touch_hold_miss.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_miss'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_miss'); }
  if(NoteIcon.touch_hold_three.src === ''){ NoteIcon.touch_hold_three.src = touch_hold_three_icon; NoteIcon.touch_hold_three.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_three'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_three'); }
  if(NoteIcon.touch_hold_two.src === ''){ NoteIcon.touch_hold_two.src = touch_hold_two_icon; NoteIcon.touch_hold_two.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_two'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_hold_two'); }
  if(NoteIcon.touch_just.src === ''){ NoteIcon.touch_just.src = touch_just_icon; NoteIcon.touch_just.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_just'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_just'); }
  if(NoteIcon.touch_slide.src === ''){ NoteIcon.touch_slide.src = touch_slide_icon; NoteIcon.touch_slide.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_slide'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_slide'); }
  if(NoteIcon.touch_three.src === ''){ NoteIcon.touch_three.src = touch_three_icon; NoteIcon.touch_three.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_three'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_three'); }
  if(NoteIcon.touch_trap.src === ''){ NoteIcon.touch_trap.src = touch_trap_icon; NoteIcon.touch_trap.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_trap'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_trap'); }
  if(NoteIcon.touch_trap_center.src === ''){ NoteIcon.touch_trap_center.src = touch_trap_center_icon; NoteIcon.touch_trap_center.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_trap_center'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_trap_center'); }
  if(NoteIcon.touch_two.src === ''){ NoteIcon.touch_two.src = touch_two_icon; NoteIcon.touch_two.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_two'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'touch_two'); }
  if(NoteIcon.wifi_0.src === ''){ NoteIcon.wifi_0.src = wifi_0_icon; NoteIcon.wifi_0.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_0'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_0'); }
  if(NoteIcon.wifi_1.src === ''){ NoteIcon.wifi_1.src = wifi_1_icon; NoteIcon.wifi_1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_1'); }
  if(NoteIcon.wifi_10.src === ''){ NoteIcon.wifi_10.src = wifi_10_icon; NoteIcon.wifi_10.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_10'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_10'); }
  if(NoteIcon.wifi_2.src === ''){ NoteIcon.wifi_2.src = wifi_2_icon; NoteIcon.wifi_2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_2'); }
  if(NoteIcon.wifi_3.src === ''){ NoteIcon.wifi_3.src = wifi_3_icon; NoteIcon.wifi_3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_3'); }
  if(NoteIcon.wifi_4.src === ''){ NoteIcon.wifi_4.src = wifi_4_icon; NoteIcon.wifi_4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_4'); }
  if(NoteIcon.wifi_5.src === ''){ NoteIcon.wifi_5.src = wifi_5_icon; NoteIcon.wifi_5.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_5'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_5'); }
  if(NoteIcon.wifi_6.src === ''){ NoteIcon.wifi_6.src = wifi_6_icon; NoteIcon.wifi_6.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_6'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_6'); }
  if(NoteIcon.wifi_7.src === ''){ NoteIcon.wifi_7.src = wifi_7_icon; NoteIcon.wifi_7.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_7'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_7'); }
  if(NoteIcon.wifi_8.src === ''){ NoteIcon.wifi_8.src = wifi_8_icon; NoteIcon.wifi_8.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_8'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_8'); }
  if(NoteIcon.wifi_9.src === ''){ NoteIcon.wifi_9.src = wifi_9_icon; NoteIcon.wifi_9.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_9'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_9'); }
  if(NoteIcon.wifi_break_0.src === ''){ NoteIcon.wifi_break_0.src = wifi_break_0_icon; NoteIcon.wifi_break_0.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_0'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_0'); }
  if(NoteIcon.wifi_break_1.src === ''){ NoteIcon.wifi_break_1.src = wifi_break_1_icon; NoteIcon.wifi_break_1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_1'); }
  if(NoteIcon.wifi_break_10.src === ''){ NoteIcon.wifi_break_10.src = wifi_break_10_icon; NoteIcon.wifi_break_10.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_10'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_10'); }
  if(NoteIcon.wifi_break_2.src === ''){ NoteIcon.wifi_break_2.src = wifi_break_2_icon; NoteIcon.wifi_break_2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_2'); }
  if(NoteIcon.wifi_break_3.src === ''){ NoteIcon.wifi_break_3.src = wifi_break_3_icon; NoteIcon.wifi_break_3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_3'); }
  if(NoteIcon.wifi_break_4.src === ''){ NoteIcon.wifi_break_4.src = wifi_break_4_icon; NoteIcon.wifi_break_4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_4'); }
  if(NoteIcon.wifi_break_5.src === ''){ NoteIcon.wifi_break_5.src = wifi_break_5_icon; NoteIcon.wifi_break_5.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_5'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_5'); }
  if(NoteIcon.wifi_break_6.src === ''){ NoteIcon.wifi_break_6.src = wifi_break_6_icon; NoteIcon.wifi_break_6.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_6'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_6'); }
  if(NoteIcon.wifi_break_7.src === ''){ NoteIcon.wifi_break_7.src = wifi_break_7_icon; NoteIcon.wifi_break_7.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_7'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_7'); }
  if(NoteIcon.wifi_break_8.src === ''){ NoteIcon.wifi_break_8.src = wifi_break_8_icon; NoteIcon.wifi_break_8.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_8'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_8'); }
  if(NoteIcon.wifi_break_9.src === ''){ NoteIcon.wifi_break_9.src = wifi_break_9_icon; NoteIcon.wifi_break_9.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_9'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_break_9'); }
  if(NoteIcon.wifi_each_0.src === ''){ NoteIcon.wifi_each_0.src = wifi_each_0_icon; NoteIcon.wifi_each_0.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_0'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_0'); }
  if(NoteIcon.wifi_each_1.src === ''){ NoteIcon.wifi_each_1.src = wifi_each_1_icon; NoteIcon.wifi_each_1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_1'); }
  if(NoteIcon.wifi_each_10.src === ''){ NoteIcon.wifi_each_10.src = wifi_each_10_icon; NoteIcon.wifi_each_10.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_10'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_10'); }
  if(NoteIcon.wifi_each_2.src === ''){ NoteIcon.wifi_each_2.src = wifi_each_2_icon; NoteIcon.wifi_each_2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_2'); }
  if(NoteIcon.wifi_each_3.src === ''){ NoteIcon.wifi_each_3.src = wifi_each_3_icon; NoteIcon.wifi_each_3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_3'); }
  if(NoteIcon.wifi_each_4.src === ''){ NoteIcon.wifi_each_4.src = wifi_each_4_icon; NoteIcon.wifi_each_4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_4'); }
  if(NoteIcon.wifi_each_5.src === ''){ NoteIcon.wifi_each_5.src = wifi_each_5_icon; NoteIcon.wifi_each_5.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_5'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_5'); }
  if(NoteIcon.wifi_each_6.src === ''){ NoteIcon.wifi_each_6.src = wifi_each_6_icon; NoteIcon.wifi_each_6.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_6'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_6'); }
  if(NoteIcon.wifi_each_7.src === ''){ NoteIcon.wifi_each_7.src = wifi_each_7_icon; NoteIcon.wifi_each_7.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_7'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_7'); }
  if(NoteIcon.wifi_each_8.src === ''){ NoteIcon.wifi_each_8.src = wifi_each_8_icon; NoteIcon.wifi_each_8.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_8'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_8'); }
  if(NoteIcon.wifi_each_9.src === ''){ NoteIcon.wifi_each_9.src = wifi_each_9_icon; NoteIcon.wifi_each_9.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_9'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_each_9'); }
  if(NoteIcon.wifi_ex_0.src === ''){ NoteIcon.wifi_ex_0.src = wifi_ex_0_icon; NoteIcon.wifi_ex_0.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_0'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_0'); }
  if(NoteIcon.wifi_ex_1.src === ''){ NoteIcon.wifi_ex_1.src = wifi_ex_1_icon; NoteIcon.wifi_ex_1.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_1'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_1'); }
  if(NoteIcon.wifi_ex_10.src === ''){ NoteIcon.wifi_ex_10.src = wifi_ex_10_icon; NoteIcon.wifi_ex_10.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_10'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_10'); }
  if(NoteIcon.wifi_ex_2.src === ''){ NoteIcon.wifi_ex_2.src = wifi_ex_2_icon; NoteIcon.wifi_ex_2.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_2'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_2'); }
  if(NoteIcon.wifi_ex_3.src === ''){ NoteIcon.wifi_ex_3.src = wifi_ex_3_icon; NoteIcon.wifi_ex_3.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_3'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_3'); }
  if(NoteIcon.wifi_ex_4.src === ''){ NoteIcon.wifi_ex_4.src = wifi_ex_4_icon; NoteIcon.wifi_ex_4.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_4'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_4'); }
  if(NoteIcon.wifi_ex_5.src === ''){ NoteIcon.wifi_ex_5.src = wifi_ex_5_icon; NoteIcon.wifi_ex_5.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_5'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_5'); }
  if(NoteIcon.wifi_ex_6.src === ''){ NoteIcon.wifi_ex_6.src = wifi_ex_6_icon; NoteIcon.wifi_ex_6.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_6'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_6'); }
  if(NoteIcon.wifi_ex_7.src === ''){ NoteIcon.wifi_ex_7.src = wifi_ex_7_icon; NoteIcon.wifi_ex_7.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_7'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_7'); }
  if(NoteIcon.wifi_ex_8.src === ''){ NoteIcon.wifi_ex_8.src = wifi_ex_8_icon; NoteIcon.wifi_ex_8.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_8'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_8'); }
  if(NoteIcon.wifi_ex_9.src === ''){ NoteIcon.wifi_ex_9.src = wifi_ex_9_icon; NoteIcon.wifi_ex_9.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_9'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'wifi_ex_9'); }
};
