// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成したファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

import UI_GAM_Break_icon from '../resource/maimai_img/judge/UI_GAM_Break.png';
import UI_GAM_Critical_icon from '../resource/maimai_img/judge/UI_GAM_Critical.png';
import UI_GAM_Critical_Break_icon from '../resource/maimai_img/judge/UI_GAM_Critical_Break.png';
import UI_GAM_Fast_icon from '../resource/maimai_img/judge/UI_GAM_Fast.png';
import UI_GAM_FastGood_icon from '../resource/maimai_img/judge/UI_GAM_FastGood.png';
import UI_GAM_FastGreat_icon from '../resource/maimai_img/judge/UI_GAM_FastGreat.png';
import UI_GAM_FastPerfect_icon from '../resource/maimai_img/judge/UI_GAM_FastPerfect.png';
import UI_GAM_Good_icon from '../resource/maimai_img/judge/UI_GAM_Good.png';
import UI_GAM_Great_icon from '../resource/maimai_img/judge/UI_GAM_Great.png';
import UI_GAM_Late_icon from '../resource/maimai_img/judge/UI_GAM_Late.png';
import UI_GAM_LateGood_icon from '../resource/maimai_img/judge/UI_GAM_LateGood.png';
import UI_GAM_LateGreat_icon from '../resource/maimai_img/judge/UI_GAM_LateGreat.png';
import UI_GAM_LatePerfect_icon from '../resource/maimai_img/judge/UI_GAM_LatePerfect.png';
import UI_GAM_Miss_icon from '../resource/maimai_img/judge/UI_GAM_Miss.png';
import UI_GAM_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_Perfect.png';
import UI_GAM_Perfect_Break_icon from '../resource/maimai_img/judge/UI_GAM_Perfect_Break.png';
import UI_GAM_SlideCircle_L_Critical_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_Critical.png';
import UI_GAM_SlideCircle_L_Fast_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_Fast.png';
import UI_GAM_SlideCircle_L_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastGood_01.png';
import UI_GAM_SlideCircle_L_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastGood_02.png';
import UI_GAM_SlideCircle_L_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastGreat_01.png';
import UI_GAM_SlideCircle_L_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastGreat_02.png';
import UI_GAM_SlideCircle_L_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastPerfect_01.png';
import UI_GAM_SlideCircle_L_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_FastPerfect_02.png';
import UI_GAM_SlideCircle_L_Late_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_Late.png';
import UI_GAM_SlideCircle_L_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LateGood_01.png';
import UI_GAM_SlideCircle_L_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LateGood_02.png';
import UI_GAM_SlideCircle_L_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LateGreat_01.png';
import UI_GAM_SlideCircle_L_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LateGreat_02.png';
import UI_GAM_SlideCircle_L_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LatePerfect_01.png';
import UI_GAM_SlideCircle_L_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_LatePerfect_02.png';
import UI_GAM_SlideCircle_L_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_L_Perfect.png';
import UI_GAM_SlideCircle_R_Critical_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_Critical.png';
import UI_GAM_SlideCircle_R_Fast_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_Fast.png';
import UI_GAM_SlideCircle_R_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastGood_01.png';
import UI_GAM_SlideCircle_R_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastGood_02.png';
import UI_GAM_SlideCircle_R_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastGreat_01.png';
import UI_GAM_SlideCircle_R_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastGreat_02.png';
import UI_GAM_SlideCircle_R_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastPerfect_01.png';
import UI_GAM_SlideCircle_R_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_FastPerfect_02.png';
import UI_GAM_SlideCircle_R_Late_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_Late.png';
import UI_GAM_SlideCircle_R_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LateGood_01.png';
import UI_GAM_SlideCircle_R_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LateGood_02.png';
import UI_GAM_SlideCircle_R_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LateGreat_01.png';
import UI_GAM_SlideCircle_R_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LateGreat_02.png';
import UI_GAM_SlideCircle_R_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LatePerfect_01.png';
import UI_GAM_SlideCircle_R_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_LatePerfect_02.png';
import UI_GAM_SlideCircle_R_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_SlideCircle_R_Perfect.png';
import UI_GAM_SlideFan_D_Critical_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_Critical.png';
import UI_GAM_SlideFan_D_Fast_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_Fast.png';
import UI_GAM_SlideFan_D_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastGood_01.png';
import UI_GAM_SlideFan_D_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastGood_02.png';
import UI_GAM_SlideFan_D_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastGreat_01.png';
import UI_GAM_SlideFan_D_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastGreat_02.png';
import UI_GAM_SlideFan_D_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastPerfect_01.png';
import UI_GAM_SlideFan_D_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_FastPerfect_02.png';
import UI_GAM_SlideFan_D_Late_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_Late.png';
import UI_GAM_SlideFan_D_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LateGood_01.png';
import UI_GAM_SlideFan_D_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LateGood_02.png';
import UI_GAM_SlideFan_D_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LateGreat_01.png';
import UI_GAM_SlideFan_D_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LateGreat_02.png';
import UI_GAM_SlideFan_D_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LatePerfect_01.png';
import UI_GAM_SlideFan_D_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_LatePerfect_02.png';
import UI_GAM_SlideFan_D_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_D_Perfect.png';
import UI_GAM_SlideFan_U_Critical_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_Critical.png';
import UI_GAM_SlideFan_U_Fast_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_Fast.png';
import UI_GAM_SlideFan_U_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastGood_01.png';
import UI_GAM_SlideFan_U_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastGood_02.png';
import UI_GAM_SlideFan_U_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastGreat_01.png';
import UI_GAM_SlideFan_U_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastGreat_02.png';
import UI_GAM_SlideFan_U_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastPerfect_01.png';
import UI_GAM_SlideFan_U_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_FastPerfect_02.png';
import UI_GAM_SlideFan_U_Late_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_Late.png';
import UI_GAM_SlideFan_U_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LateGood_01.png';
import UI_GAM_SlideFan_U_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LateGood_02.png';
import UI_GAM_SlideFan_U_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LateGreat_01.png';
import UI_GAM_SlideFan_U_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LateGreat_02.png';
import UI_GAM_SlideFan_U_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LatePerfect_01.png';
import UI_GAM_SlideFan_U_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_LatePerfect_02.png';
import UI_GAM_SlideFan_U_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_SlideFan_U_Perfect.png';
import UI_GAM_Slide_L_Critical_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_Critical.png';
import UI_GAM_Slide_L_Fast_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_Fast.png';
import UI_GAM_Slide_L_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastGood_01.png';
import UI_GAM_Slide_L_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastGood_02.png';
import UI_GAM_Slide_L_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastGreat_01.png';
import UI_GAM_Slide_L_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastGreat_02.png';
import UI_GAM_Slide_L_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastPerfect_01.png';
import UI_GAM_Slide_L_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_FastPerfect_02.png';
import UI_GAM_Slide_L_Late_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_Late.png';
import UI_GAM_Slide_L_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LateGood_01.png';
import UI_GAM_Slide_L_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LateGood_02.png';
import UI_GAM_Slide_L_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LateGreat_01.png';
import UI_GAM_Slide_L_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LateGreat_02.png';
import UI_GAM_Slide_L_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LatePerfect_01.png';
import UI_GAM_Slide_L_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_LatePerfect_02.png';
import UI_GAM_Slide_L_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_Slide_L_Perfect.png';
import UI_GAM_Slide_R_Critical_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_Critical.png';
import UI_GAM_Slide_R_Fast_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_Fast.png';
import UI_GAM_Slide_R_FastGood_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastGood_01.png';
import UI_GAM_Slide_R_FastGood_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastGood_02.png';
import UI_GAM_Slide_R_FastGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastGreat_01.png';
import UI_GAM_Slide_R_FastGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastGreat_02.png';
import UI_GAM_Slide_R_FastPerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastPerfect_01.png';
import UI_GAM_Slide_R_FastPerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_FastPerfect_02.png';
import UI_GAM_Slide_R_Late_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_Late.png';
import UI_GAM_Slide_R_LateGood_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LateGood_01.png';
import UI_GAM_Slide_R_LateGood_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LateGood_02.png';
import UI_GAM_Slide_R_LateGreat_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LateGreat_01.png';
import UI_GAM_Slide_R_LateGreat_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LateGreat_02.png';
import UI_GAM_Slide_R_LatePerfect_01_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LatePerfect_01.png';
import UI_GAM_Slide_R_LatePerfect_02_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_LatePerfect_02.png';
import UI_GAM_Slide_R_Perfect_icon from '../resource/maimai_img/judge/UI_GAM_Slide_R_Perfect.png';

export const JudgeIcon = {
    UI_GAM_Break: new Image(),
    UI_GAM_Critical: new Image(),
    UI_GAM_Critical_Break: new Image(),
    UI_GAM_Fast: new Image(),
    UI_GAM_FastGood: new Image(),
    UI_GAM_FastGreat: new Image(),
    UI_GAM_FastPerfect: new Image(),
    UI_GAM_Good: new Image(),
    UI_GAM_Great: new Image(),
    UI_GAM_Late: new Image(),
    UI_GAM_LateGood: new Image(),
    UI_GAM_LateGreat: new Image(),
    UI_GAM_LatePerfect: new Image(),
    UI_GAM_Miss: new Image(),
    UI_GAM_Perfect: new Image(),
    UI_GAM_Perfect_Break: new Image(),
    UI_GAM_SlideCircle_L_Critical: new Image(),
    UI_GAM_SlideCircle_L_Fast: new Image(),
    UI_GAM_SlideCircle_L_FastGood_01: new Image(),
    UI_GAM_SlideCircle_L_FastGood_02: new Image(),
    UI_GAM_SlideCircle_L_FastGreat_01: new Image(),
    UI_GAM_SlideCircle_L_FastGreat_02: new Image(),
    UI_GAM_SlideCircle_L_FastPerfect_01: new Image(),
    UI_GAM_SlideCircle_L_FastPerfect_02: new Image(),
    UI_GAM_SlideCircle_L_Late: new Image(),
    UI_GAM_SlideCircle_L_LateGood_01: new Image(),
    UI_GAM_SlideCircle_L_LateGood_02: new Image(),
    UI_GAM_SlideCircle_L_LateGreat_01: new Image(),
    UI_GAM_SlideCircle_L_LateGreat_02: new Image(),
    UI_GAM_SlideCircle_L_LatePerfect_01: new Image(),
    UI_GAM_SlideCircle_L_LatePerfect_02: new Image(),
    UI_GAM_SlideCircle_L_Perfect: new Image(),
    UI_GAM_SlideCircle_R_Critical: new Image(),
    UI_GAM_SlideCircle_R_Fast: new Image(),
    UI_GAM_SlideCircle_R_FastGood_01: new Image(),
    UI_GAM_SlideCircle_R_FastGood_02: new Image(),
    UI_GAM_SlideCircle_R_FastGreat_01: new Image(),
    UI_GAM_SlideCircle_R_FastGreat_02: new Image(),
    UI_GAM_SlideCircle_R_FastPerfect_01: new Image(),
    UI_GAM_SlideCircle_R_FastPerfect_02: new Image(),
    UI_GAM_SlideCircle_R_Late: new Image(),
    UI_GAM_SlideCircle_R_LateGood_01: new Image(),
    UI_GAM_SlideCircle_R_LateGood_02: new Image(),
    UI_GAM_SlideCircle_R_LateGreat_01: new Image(),
    UI_GAM_SlideCircle_R_LateGreat_02: new Image(),
    UI_GAM_SlideCircle_R_LatePerfect_01: new Image(),
    UI_GAM_SlideCircle_R_LatePerfect_02: new Image(),
    UI_GAM_SlideCircle_R_Perfect: new Image(),
    UI_GAM_SlideFan_D_Critical: new Image(),
    UI_GAM_SlideFan_D_Fast: new Image(),
    UI_GAM_SlideFan_D_FastGood_01: new Image(),
    UI_GAM_SlideFan_D_FastGood_02: new Image(),
    UI_GAM_SlideFan_D_FastGreat_01: new Image(),
    UI_GAM_SlideFan_D_FastGreat_02: new Image(),
    UI_GAM_SlideFan_D_FastPerfect_01: new Image(),
    UI_GAM_SlideFan_D_FastPerfect_02: new Image(),
    UI_GAM_SlideFan_D_Late: new Image(),
    UI_GAM_SlideFan_D_LateGood_01: new Image(),
    UI_GAM_SlideFan_D_LateGood_02: new Image(),
    UI_GAM_SlideFan_D_LateGreat_01: new Image(),
    UI_GAM_SlideFan_D_LateGreat_02: new Image(),
    UI_GAM_SlideFan_D_LatePerfect_01: new Image(),
    UI_GAM_SlideFan_D_LatePerfect_02: new Image(),
    UI_GAM_SlideFan_D_Perfect: new Image(),
    UI_GAM_SlideFan_U_Critical: new Image(),
    UI_GAM_SlideFan_U_Fast: new Image(),
    UI_GAM_SlideFan_U_FastGood_01: new Image(),
    UI_GAM_SlideFan_U_FastGood_02: new Image(),
    UI_GAM_SlideFan_U_FastGreat_01: new Image(),
    UI_GAM_SlideFan_U_FastGreat_02: new Image(),
    UI_GAM_SlideFan_U_FastPerfect_01: new Image(),
    UI_GAM_SlideFan_U_FastPerfect_02: new Image(),
    UI_GAM_SlideFan_U_Late: new Image(),
    UI_GAM_SlideFan_U_LateGood_01: new Image(),
    UI_GAM_SlideFan_U_LateGood_02: new Image(),
    UI_GAM_SlideFan_U_LateGreat_01: new Image(),
    UI_GAM_SlideFan_U_LateGreat_02: new Image(),
    UI_GAM_SlideFan_U_LatePerfect_01: new Image(),
    UI_GAM_SlideFan_U_LatePerfect_02: new Image(),
    UI_GAM_SlideFan_U_Perfect: new Image(),
    UI_GAM_Slide_L_Critical: new Image(),
    UI_GAM_Slide_L_Fast: new Image(),
    UI_GAM_Slide_L_FastGood_01: new Image(),
    UI_GAM_Slide_L_FastGood_02: new Image(),
    UI_GAM_Slide_L_FastGreat_01: new Image(),
    UI_GAM_Slide_L_FastGreat_02: new Image(),
    UI_GAM_Slide_L_FastPerfect_01: new Image(),
    UI_GAM_Slide_L_FastPerfect_02: new Image(),
    UI_GAM_Slide_L_Late: new Image(),
    UI_GAM_Slide_L_LateGood_01: new Image(),
    UI_GAM_Slide_L_LateGood_02: new Image(),
    UI_GAM_Slide_L_LateGreat_01: new Image(),
    UI_GAM_Slide_L_LateGreat_02: new Image(),
    UI_GAM_Slide_L_LatePerfect_01: new Image(),
    UI_GAM_Slide_L_LatePerfect_02: new Image(),
    UI_GAM_Slide_L_Perfect: new Image(),
    UI_GAM_Slide_R_Critical: new Image(),
    UI_GAM_Slide_R_Fast: new Image(),
    UI_GAM_Slide_R_FastGood_01: new Image(),
    UI_GAM_Slide_R_FastGood_02: new Image(),
    UI_GAM_Slide_R_FastGreat_01: new Image(),
    UI_GAM_Slide_R_FastGreat_02: new Image(),
    UI_GAM_Slide_R_FastPerfect_01: new Image(),
    UI_GAM_Slide_R_FastPerfect_02: new Image(),
    UI_GAM_Slide_R_Late: new Image(),
    UI_GAM_Slide_R_LateGood_01: new Image(),
    UI_GAM_Slide_R_LateGood_02: new Image(),
    UI_GAM_Slide_R_LateGreat_01: new Image(),
    UI_GAM_Slide_R_LateGreat_02: new Image(),
    UI_GAM_Slide_R_LatePerfect_01: new Image(),
    UI_GAM_Slide_R_LatePerfect_02: new Image(),
    UI_GAM_Slide_R_Perfect: new Image(),
};

export const initjudgeicons = (onProgress: (amount: number, loaded: number, name: string) => void, onload: () => void) => {
  const amount = 112;
  let loaded = 0;
  if(JudgeIcon.UI_GAM_Break.src === ''){ JudgeIcon.UI_GAM_Break.src = UI_GAM_Break_icon; JudgeIcon.UI_GAM_Break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Break'); }
  if(JudgeIcon.UI_GAM_Critical.src === ''){ JudgeIcon.UI_GAM_Critical.src = UI_GAM_Critical_icon; JudgeIcon.UI_GAM_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Critical'); }
  if(JudgeIcon.UI_GAM_Critical_Break.src === ''){ JudgeIcon.UI_GAM_Critical_Break.src = UI_GAM_Critical_Break_icon; JudgeIcon.UI_GAM_Critical_Break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Critical_Break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Critical_Break'); }
  if(JudgeIcon.UI_GAM_Fast.src === ''){ JudgeIcon.UI_GAM_Fast.src = UI_GAM_Fast_icon; JudgeIcon.UI_GAM_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Fast'); }
  if(JudgeIcon.UI_GAM_FastGood.src === ''){ JudgeIcon.UI_GAM_FastGood.src = UI_GAM_FastGood_icon; JudgeIcon.UI_GAM_FastGood.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastGood'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastGood'); }
  if(JudgeIcon.UI_GAM_FastGreat.src === ''){ JudgeIcon.UI_GAM_FastGreat.src = UI_GAM_FastGreat_icon; JudgeIcon.UI_GAM_FastGreat.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastGreat'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastGreat'); }
  if(JudgeIcon.UI_GAM_FastPerfect.src === ''){ JudgeIcon.UI_GAM_FastPerfect.src = UI_GAM_FastPerfect_icon; JudgeIcon.UI_GAM_FastPerfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastPerfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_FastPerfect'); }
  if(JudgeIcon.UI_GAM_Good.src === ''){ JudgeIcon.UI_GAM_Good.src = UI_GAM_Good_icon; JudgeIcon.UI_GAM_Good.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Good'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Good'); }
  if(JudgeIcon.UI_GAM_Great.src === ''){ JudgeIcon.UI_GAM_Great.src = UI_GAM_Great_icon; JudgeIcon.UI_GAM_Great.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Great'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Great'); }
  if(JudgeIcon.UI_GAM_Late.src === ''){ JudgeIcon.UI_GAM_Late.src = UI_GAM_Late_icon; JudgeIcon.UI_GAM_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Late'); }
  if(JudgeIcon.UI_GAM_LateGood.src === ''){ JudgeIcon.UI_GAM_LateGood.src = UI_GAM_LateGood_icon; JudgeIcon.UI_GAM_LateGood.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LateGood'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LateGood'); }
  if(JudgeIcon.UI_GAM_LateGreat.src === ''){ JudgeIcon.UI_GAM_LateGreat.src = UI_GAM_LateGreat_icon; JudgeIcon.UI_GAM_LateGreat.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LateGreat'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LateGreat'); }
  if(JudgeIcon.UI_GAM_LatePerfect.src === ''){ JudgeIcon.UI_GAM_LatePerfect.src = UI_GAM_LatePerfect_icon; JudgeIcon.UI_GAM_LatePerfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LatePerfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_LatePerfect'); }
  if(JudgeIcon.UI_GAM_Miss.src === ''){ JudgeIcon.UI_GAM_Miss.src = UI_GAM_Miss_icon; JudgeIcon.UI_GAM_Miss.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Miss'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Miss'); }
  if(JudgeIcon.UI_GAM_Perfect.src === ''){ JudgeIcon.UI_GAM_Perfect.src = UI_GAM_Perfect_icon; JudgeIcon.UI_GAM_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Perfect'); }
  if(JudgeIcon.UI_GAM_Perfect_Break.src === ''){ JudgeIcon.UI_GAM_Perfect_Break.src = UI_GAM_Perfect_Break_icon; JudgeIcon.UI_GAM_Perfect_Break.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Perfect_Break'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Perfect_Break'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_Critical.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_Critical.src = UI_GAM_SlideCircle_L_Critical_icon; JudgeIcon.UI_GAM_SlideCircle_L_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Critical'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_Fast.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_Fast.src = UI_GAM_SlideCircle_L_Fast_icon; JudgeIcon.UI_GAM_SlideCircle_L_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Fast'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastGood_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastGood_01.src = UI_GAM_SlideCircle_L_FastGood_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGood_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastGood_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastGood_02.src = UI_GAM_SlideCircle_L_FastGood_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGood_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_01.src = UI_GAM_SlideCircle_L_FastGreat_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_02.src = UI_GAM_SlideCircle_L_FastGreat_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_01.src = UI_GAM_SlideCircle_L_FastPerfect_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_02.src = UI_GAM_SlideCircle_L_FastPerfect_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_Late.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_Late.src = UI_GAM_SlideCircle_L_Late_icon; JudgeIcon.UI_GAM_SlideCircle_L_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Late'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LateGood_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LateGood_01.src = UI_GAM_SlideCircle_L_LateGood_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGood_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LateGood_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LateGood_02.src = UI_GAM_SlideCircle_L_LateGood_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGood_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_01.src = UI_GAM_SlideCircle_L_LateGreat_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_02.src = UI_GAM_SlideCircle_L_LateGreat_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_01.src = UI_GAM_SlideCircle_L_LatePerfect_01_icon; JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_02.src = UI_GAM_SlideCircle_L_LatePerfect_02_icon; JudgeIcon.UI_GAM_SlideCircle_L_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_L_Perfect.src === ''){ JudgeIcon.UI_GAM_SlideCircle_L_Perfect.src = UI_GAM_SlideCircle_L_Perfect_icon; JudgeIcon.UI_GAM_SlideCircle_L_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_L_Perfect'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_Critical.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_Critical.src = UI_GAM_SlideCircle_R_Critical_icon; JudgeIcon.UI_GAM_SlideCircle_R_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Critical'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_Fast.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_Fast.src = UI_GAM_SlideCircle_R_Fast_icon; JudgeIcon.UI_GAM_SlideCircle_R_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Fast'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastGood_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastGood_01.src = UI_GAM_SlideCircle_R_FastGood_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGood_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastGood_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastGood_02.src = UI_GAM_SlideCircle_R_FastGood_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGood_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_01.src = UI_GAM_SlideCircle_R_FastGreat_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_02.src = UI_GAM_SlideCircle_R_FastGreat_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_01.src = UI_GAM_SlideCircle_R_FastPerfect_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_02.src = UI_GAM_SlideCircle_R_FastPerfect_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_Late.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_Late.src = UI_GAM_SlideCircle_R_Late_icon; JudgeIcon.UI_GAM_SlideCircle_R_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Late'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LateGood_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LateGood_01.src = UI_GAM_SlideCircle_R_LateGood_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGood_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LateGood_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LateGood_02.src = UI_GAM_SlideCircle_R_LateGood_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGood_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_01.src = UI_GAM_SlideCircle_R_LateGreat_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_02.src = UI_GAM_SlideCircle_R_LateGreat_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_01.src = UI_GAM_SlideCircle_R_LatePerfect_01_icon; JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_02.src = UI_GAM_SlideCircle_R_LatePerfect_02_icon; JudgeIcon.UI_GAM_SlideCircle_R_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideCircle_R_Perfect.src === ''){ JudgeIcon.UI_GAM_SlideCircle_R_Perfect.src = UI_GAM_SlideCircle_R_Perfect_icon; JudgeIcon.UI_GAM_SlideCircle_R_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideCircle_R_Perfect'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_Critical.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_Critical.src = UI_GAM_SlideFan_D_Critical_icon; JudgeIcon.UI_GAM_SlideFan_D_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Critical'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_Fast.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_Fast.src = UI_GAM_SlideFan_D_Fast_icon; JudgeIcon.UI_GAM_SlideFan_D_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Fast'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastGood_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastGood_01.src = UI_GAM_SlideFan_D_FastGood_01_icon; JudgeIcon.UI_GAM_SlideFan_D_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGood_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastGood_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastGood_02.src = UI_GAM_SlideFan_D_FastGood_02_icon; JudgeIcon.UI_GAM_SlideFan_D_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGood_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastGreat_01.src = UI_GAM_SlideFan_D_FastGreat_01_icon; JudgeIcon.UI_GAM_SlideFan_D_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastGreat_02.src = UI_GAM_SlideFan_D_FastGreat_02_icon; JudgeIcon.UI_GAM_SlideFan_D_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_01.src = UI_GAM_SlideFan_D_FastPerfect_01_icon; JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_02.src = UI_GAM_SlideFan_D_FastPerfect_02_icon; JudgeIcon.UI_GAM_SlideFan_D_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_Late.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_Late.src = UI_GAM_SlideFan_D_Late_icon; JudgeIcon.UI_GAM_SlideFan_D_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Late'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LateGood_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LateGood_01.src = UI_GAM_SlideFan_D_LateGood_01_icon; JudgeIcon.UI_GAM_SlideFan_D_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGood_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LateGood_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LateGood_02.src = UI_GAM_SlideFan_D_LateGood_02_icon; JudgeIcon.UI_GAM_SlideFan_D_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGood_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LateGreat_01.src = UI_GAM_SlideFan_D_LateGreat_01_icon; JudgeIcon.UI_GAM_SlideFan_D_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LateGreat_02.src = UI_GAM_SlideFan_D_LateGreat_02_icon; JudgeIcon.UI_GAM_SlideFan_D_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_01.src = UI_GAM_SlideFan_D_LatePerfect_01_icon; JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_02.src = UI_GAM_SlideFan_D_LatePerfect_02_icon; JudgeIcon.UI_GAM_SlideFan_D_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_D_Perfect.src === ''){ JudgeIcon.UI_GAM_SlideFan_D_Perfect.src = UI_GAM_SlideFan_D_Perfect_icon; JudgeIcon.UI_GAM_SlideFan_D_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_D_Perfect'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_Critical.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_Critical.src = UI_GAM_SlideFan_U_Critical_icon; JudgeIcon.UI_GAM_SlideFan_U_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Critical'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_Fast.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_Fast.src = UI_GAM_SlideFan_U_Fast_icon; JudgeIcon.UI_GAM_SlideFan_U_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Fast'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastGood_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastGood_01.src = UI_GAM_SlideFan_U_FastGood_01_icon; JudgeIcon.UI_GAM_SlideFan_U_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGood_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastGood_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastGood_02.src = UI_GAM_SlideFan_U_FastGood_02_icon; JudgeIcon.UI_GAM_SlideFan_U_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGood_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastGreat_01.src = UI_GAM_SlideFan_U_FastGreat_01_icon; JudgeIcon.UI_GAM_SlideFan_U_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastGreat_02.src = UI_GAM_SlideFan_U_FastGreat_02_icon; JudgeIcon.UI_GAM_SlideFan_U_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_01.src = UI_GAM_SlideFan_U_FastPerfect_01_icon; JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_02.src = UI_GAM_SlideFan_U_FastPerfect_02_icon; JudgeIcon.UI_GAM_SlideFan_U_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_Late.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_Late.src = UI_GAM_SlideFan_U_Late_icon; JudgeIcon.UI_GAM_SlideFan_U_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Late'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LateGood_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LateGood_01.src = UI_GAM_SlideFan_U_LateGood_01_icon; JudgeIcon.UI_GAM_SlideFan_U_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGood_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LateGood_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LateGood_02.src = UI_GAM_SlideFan_U_LateGood_02_icon; JudgeIcon.UI_GAM_SlideFan_U_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGood_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LateGreat_01.src = UI_GAM_SlideFan_U_LateGreat_01_icon; JudgeIcon.UI_GAM_SlideFan_U_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LateGreat_02.src = UI_GAM_SlideFan_U_LateGreat_02_icon; JudgeIcon.UI_GAM_SlideFan_U_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_01.src = UI_GAM_SlideFan_U_LatePerfect_01_icon; JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_02.src = UI_GAM_SlideFan_U_LatePerfect_02_icon; JudgeIcon.UI_GAM_SlideFan_U_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_SlideFan_U_Perfect.src === ''){ JudgeIcon.UI_GAM_SlideFan_U_Perfect.src = UI_GAM_SlideFan_U_Perfect_icon; JudgeIcon.UI_GAM_SlideFan_U_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_SlideFan_U_Perfect'); }
  if(JudgeIcon.UI_GAM_Slide_L_Critical.src === ''){ JudgeIcon.UI_GAM_Slide_L_Critical.src = UI_GAM_Slide_L_Critical_icon; JudgeIcon.UI_GAM_Slide_L_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Critical'); }
  if(JudgeIcon.UI_GAM_Slide_L_Fast.src === ''){ JudgeIcon.UI_GAM_Slide_L_Fast.src = UI_GAM_Slide_L_Fast_icon; JudgeIcon.UI_GAM_Slide_L_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Fast'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastGood_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastGood_01.src = UI_GAM_Slide_L_FastGood_01_icon; JudgeIcon.UI_GAM_Slide_L_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGood_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastGood_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastGood_02.src = UI_GAM_Slide_L_FastGood_02_icon; JudgeIcon.UI_GAM_Slide_L_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGood_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastGreat_01.src = UI_GAM_Slide_L_FastGreat_01_icon; JudgeIcon.UI_GAM_Slide_L_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastGreat_02.src = UI_GAM_Slide_L_FastGreat_02_icon; JudgeIcon.UI_GAM_Slide_L_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastPerfect_01.src = UI_GAM_Slide_L_FastPerfect_01_icon; JudgeIcon.UI_GAM_Slide_L_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_FastPerfect_02.src = UI_GAM_Slide_L_FastPerfect_02_icon; JudgeIcon.UI_GAM_Slide_L_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_Late.src === ''){ JudgeIcon.UI_GAM_Slide_L_Late.src = UI_GAM_Slide_L_Late_icon; JudgeIcon.UI_GAM_Slide_L_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Late'); }
  if(JudgeIcon.UI_GAM_Slide_L_LateGood_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_LateGood_01.src = UI_GAM_Slide_L_LateGood_01_icon; JudgeIcon.UI_GAM_Slide_L_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGood_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_LateGood_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_LateGood_02.src = UI_GAM_Slide_L_LateGood_02_icon; JudgeIcon.UI_GAM_Slide_L_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGood_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_LateGreat_01.src = UI_GAM_Slide_L_LateGreat_01_icon; JudgeIcon.UI_GAM_Slide_L_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_LateGreat_02.src = UI_GAM_Slide_L_LateGreat_02_icon; JudgeIcon.UI_GAM_Slide_L_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_Slide_L_LatePerfect_01.src = UI_GAM_Slide_L_LatePerfect_01_icon; JudgeIcon.UI_GAM_Slide_L_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_Slide_L_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_Slide_L_LatePerfect_02.src = UI_GAM_Slide_L_LatePerfect_02_icon; JudgeIcon.UI_GAM_Slide_L_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_Slide_L_Perfect.src === ''){ JudgeIcon.UI_GAM_Slide_L_Perfect.src = UI_GAM_Slide_L_Perfect_icon; JudgeIcon.UI_GAM_Slide_L_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_L_Perfect'); }
  if(JudgeIcon.UI_GAM_Slide_R_Critical.src === ''){ JudgeIcon.UI_GAM_Slide_R_Critical.src = UI_GAM_Slide_R_Critical_icon; JudgeIcon.UI_GAM_Slide_R_Critical.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Critical'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Critical'); }
  if(JudgeIcon.UI_GAM_Slide_R_Fast.src === ''){ JudgeIcon.UI_GAM_Slide_R_Fast.src = UI_GAM_Slide_R_Fast_icon; JudgeIcon.UI_GAM_Slide_R_Fast.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Fast'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Fast'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastGood_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastGood_01.src = UI_GAM_Slide_R_FastGood_01_icon; JudgeIcon.UI_GAM_Slide_R_FastGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGood_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastGood_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastGood_02.src = UI_GAM_Slide_R_FastGood_02_icon; JudgeIcon.UI_GAM_Slide_R_FastGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGood_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastGreat_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastGreat_01.src = UI_GAM_Slide_R_FastGreat_01_icon; JudgeIcon.UI_GAM_Slide_R_FastGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGreat_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastGreat_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastGreat_02.src = UI_GAM_Slide_R_FastGreat_02_icon; JudgeIcon.UI_GAM_Slide_R_FastGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastGreat_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastPerfect_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastPerfect_01.src = UI_GAM_Slide_R_FastPerfect_01_icon; JudgeIcon.UI_GAM_Slide_R_FastPerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastPerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastPerfect_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_FastPerfect_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_FastPerfect_02.src = UI_GAM_Slide_R_FastPerfect_02_icon; JudgeIcon.UI_GAM_Slide_R_FastPerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastPerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_FastPerfect_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_Late.src === ''){ JudgeIcon.UI_GAM_Slide_R_Late.src = UI_GAM_Slide_R_Late_icon; JudgeIcon.UI_GAM_Slide_R_Late.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Late'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Late'); }
  if(JudgeIcon.UI_GAM_Slide_R_LateGood_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_LateGood_01.src = UI_GAM_Slide_R_LateGood_01_icon; JudgeIcon.UI_GAM_Slide_R_LateGood_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGood_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGood_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_LateGood_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_LateGood_02.src = UI_GAM_Slide_R_LateGood_02_icon; JudgeIcon.UI_GAM_Slide_R_LateGood_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGood_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGood_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_LateGreat_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_LateGreat_01.src = UI_GAM_Slide_R_LateGreat_01_icon; JudgeIcon.UI_GAM_Slide_R_LateGreat_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGreat_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGreat_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_LateGreat_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_LateGreat_02.src = UI_GAM_Slide_R_LateGreat_02_icon; JudgeIcon.UI_GAM_Slide_R_LateGreat_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGreat_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LateGreat_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_LatePerfect_01.src === ''){ JudgeIcon.UI_GAM_Slide_R_LatePerfect_01.src = UI_GAM_Slide_R_LatePerfect_01_icon; JudgeIcon.UI_GAM_Slide_R_LatePerfect_01.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LatePerfect_01'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LatePerfect_01'); }
  if(JudgeIcon.UI_GAM_Slide_R_LatePerfect_02.src === ''){ JudgeIcon.UI_GAM_Slide_R_LatePerfect_02.src = UI_GAM_Slide_R_LatePerfect_02_icon; JudgeIcon.UI_GAM_Slide_R_LatePerfect_02.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LatePerfect_02'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_LatePerfect_02'); }
  if(JudgeIcon.UI_GAM_Slide_R_Perfect.src === ''){ JudgeIcon.UI_GAM_Slide_R_Perfect.src = UI_GAM_Slide_R_Perfect_icon; JudgeIcon.UI_GAM_Slide_R_Perfect.onload = () => { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Perfect'); }} else { loaded++; if (loaded >= amount) onload(); else onProgress(amount, loaded, 'UI_GAM_Slide_R_Perfect'); }
};
