/** 测试用谱面 */
export const sheetdata2 = {
  notes: `(124.0){4},,3h[4:1],C1,
  {16}1hx[4:1]/6xp4[4:1],,,,,,,,2,,,3/5,,,4/6,,
  {8}5/7h[4:1],6,5h[4:1],6,7h[8:1],8x,7x/8x,,
  {16}1h[4:1]/6p4[4:1],,,,,,,,2,,,3/5,,,4/6,,
  {8}5/7h[4:1],6,5h[4:1],6,7,1x/8x,2b/7b,,
  {8}4-2[8:1],,6V84[4:1],,3,,1,1,
  {8}2/4,3,4/5,6,5/7,6,4/5,,
  {8}3/6-8[8:1],,3V15[4:1],,1,8,7,6,
  {8}5/7,6,4/5,3,2/4,3,4x/5x,,
  {16}6,5,6,,4/7,,3,4,3h[4:1],,,,C1,,,,
  {16}7,8,7,,1/6,,2,1,2h[4:1],,,,C1,,,,
  {2}8h[4:1]/7^5[8:1],1h[4:1]/2^4[8:1],
  {8}5,,4,4,5,5,C1,,
  {16}8h[4:1]/3q5[4:1],,,,,,,,7,,,4/6,,,3/5,,
  {8}4/2h[4:1],3,4h[4:1],3,2,B6/B7/E7,B6/B7/E7,,
  {16}8hx[4:1]/3xq5[4:1],,,,,,,,7,,,4/6,,,3/5,,
  {8}4/2h[4:1],3,4h[4:1],3,2,B6/B7/E7,B6/B7/E7,,
  {8}4^2[8:1],,6V84[4:1],,1,,2,3,
  {8}2/4,3,4/5,6,5/7,6,4/5,,
  {8}6x/3x^6[4:1],3x,6xqq3[8:3],6x,7x,,8,,
  {8}2/4,3,4/5,6,5/7,6,4x/5x,,
  {16}3,4,3,4,2,,6,5,6,5,7h[8:1],,C1,,,,
  {16}2,1,2,1,3,,7,8,7,8,6h[8:1],,C1,,,,
  {2}1h[4:1]/2q6[8:1],8h[4:1]/7p3[8:1],
  {4}1>8[16:5]/8q1[16:5],,,2/6,
  {16}5hx[8:1]/7hx[8:1],,,4hx[8:1]/6hx[8:1],,,3x/5x,,,,E3,,E4,,,,
  {16}5hx[8:1]/7hx[8:1],,,4hx[8:1]/6hx[8:1],,,3x/5x,,,,B3,,E5,,,4x/6x,
  {16},,,,3/5,,,6h[16:3],,,5,,4/7,,,,
  {8}3/5,,4/6,,3/4,3/4,2/5,,
  {16}4hx[8:1]/6hx[8:1],,,3hx[8:1]/5hx[8:1],,,2x/6x,,,,E8,,E1,,E2,,
  {16}3hx[8:1]/5hx[8:1],,,4hx[8:1]/6hx[8:1],,,3x/7x,,,,E4,,E5,,E6,,
  {8}2,1,8,7,6,5,4,3x/7x-3[8:1],
  {8},1xs5[4:1],,5x,6x,,8x,,
  {16}6,5,,3,4,,2,,4/7,,6,,3qq4[4:1]/5pp4[4:1],,2,,
  {8}3b/5b,,,6,2/5,3,4qq5[4:1]/6pp5[4:1],7,
  {8}4/6,,,3,4/7,6,3qq8[4:1]/5pp8[4:1],2,
  {8}3/5,,,7,2/6,3,4>5[4:1]/5p4[4:1],6,
  {8}4b/5b,,,3,2/4,3,5/7,6,
  {16}2/4,,3,8,,6,1,,3-8[8:1]/7-4[8:1],,,,3/7,,,,
  {8}2/4,3,4/5,6,5/7,6,4/5,3,
  {8}4/2q6[8:1],2,1,8,7p3[8:1],7,8,1,
  {4}1x^4[8:1]*^6[8:1],1xw5[8:1],1x,3x/7x,
  {8}7x/C1f,,2x/C1f,,8/C1f,8,1/C1f,1,
  {1}C1f/8b<1[8:7],
  {4},,8z4[4:1],2h[1:2],
  {4},E4,,E6,
  {4},E1,C1,E5,
  {16}6h[4:1],,,,5,6,,4/7,,,3,4,,2/5,,,
  {16}6h[4:1],,,,5,6,,4/7,,,3,4,,2/5,,,
  {16}2h[4:1],,,,1,2,,3/8,,,7,8,,1/6,,,
  {16}2h[4:1],,,,1,2,,3/8,,,4/5,,3b/6b,,,,
  {8}7-5[8:1],,2V48[4:1],,8,,7,6,
  {8}4/5,3,1/2,8,4/7,B6/E6,B3/E4,,
  {8}2x-4[8:1],,7V51[4:1],,1,2,3,4,
  {8}5/6,7,1/8,2,3/5,B5/E5,B1/E1,,
  {4}6hx[1:1],C1,,C1,
  {4}3hx[1:1],C1,,C1,
  {4}7hx[2:1],C1,2hx[2:1],C1,
  {16}7hx[4:1],,,,8hx[4:1],,,,1hx[8:1],,2hx[8:1],,3x,4x,5x,6x,
  {4}7x<8[16:5],,,5w1[8:1],
  {625}5b,,,,,,,,,,,,,{607}C1f,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,{8}7,3/5,B2/E2,4qq5[4:1]/6pp5[4:1],B7/B8,
  {8}4/6,,,2,4/6,B6/B7,3qq4[4:1]/5pp4[4:1],B1/B2,
  {8}3/5,,,7,4/5,B2/E2,4>5[4:1]/5p4[4:1],B7/E8,
  {8}4b/5b,,,3,2/4,3,5/7,6,
  {16}2/4,,3,8,,6,1,,3-8[8:1]/7-4[8:1],,,,3/7,,,,
  {8}2/4,3,4/5,6,5/7,6,4/5,3,
  {8}4/2V46[16:3],2,1,8,7V53[16:3],7,8,1,
  {4}1x^4[8:1]*^6[8:1],1xw5[8:1],1x,3x/7x,
  {8}2h[8:1]/5h[8:1],,4h[8:1]/7h[8:1],,1/8,1/8,2pp8[16:5]/7qq1[16:5],4/5,
  {8}2b/7b,,,,1x/8x,,5/7,5h[4:1]/7h[4:1],
  {8},,2h[8:1]/4h[8:1],,{641}E8,,,,,,,,,,,,,,,,,,,,{639}E7,,,,,,,,,,,,,,,,,,,,{16}E6,,,{641}E2,,,,,,,,,,,,,,,,,,,,{639}E3,,,,,,,,,,,,,,,,,,,,{16}E4,,,
  {641}E1,,,,,,,,,,,,,,,,,,,,{639}C1,,,,,,,,,,,,,,,,,,,,{16}E5,,,1x/8x,,7,7,5h[4:1],,8,,6h[4:1],,8,,
  {16}5,,,,1x/8x,,2,2,4h[4:1],,1x,,3hx[8:1],,1,,
  {16}2/8,,,,4x/5x,,7,7,5h[4:1],,E8,,6h[4:1],,E1,,
  {16}5,,,,4x/5x,,2,2,4h[4:1],,E2,,3h[4:1],,E1,,
  {8}4,2/5,4/7,1/8,7^2[8:1],2v4[8:1],4,,,
  
  
  E
  `,
  wholebpm: 124,
  first: 2.155,
};
